import React from 'react';
import PropTypes from 'prop-types';

function Feature({ title, children, icon, className }) {
    return (
        <div className="group text-center space-y-2 md:flex-1 flex flex-col justify-start">
            {icon && (
                <div
                    className={`${className} transition-transform duration-200 ease-in-out text-center text-5xl flex items-center justify-center transform group-hover:-translate-y-6`}
                >
                    {icon}
                </div>
            )}
            <h3
                className={`${className} md:text-black-500 transition-colors transition-duration-500 ease-in-out  group-hover:${className} text-2xl font-bold`}
            >
                {title}
            </h3>

            <div className="flex-1">{children}</div>
        </div>
    );
}

Feature.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    icon: PropTypes.node,
};

export default Feature;
