import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ServiceTable from './ServiceTable';

function Service({ title, data }) {
    const [activeTab, setTab] = useState('normal');

    const tableData = data.map(({ title, prices }) => {
        return {
            title,
            prices: prices.map(({ category, amounts }) => {
                let amount = amounts.normal;

                if (activeTab === 'subscriber') {
                    amount = amounts.subscribed;
                }

                return { category, amount };
            }),
        };
    });

    return (
        <div className="container mx-auto">
            <h3 className="text-3xl font-medium mx-auto text-center">
                {title}
            </h3>

            <div className="container px-5 mx-auto">
                <div className="flex flex-col text-center w-full ">
                    <div className="flex mx-auto border-2 border-primary-700 rounded overflow-hidden mt-6">
                        <button
                            className={`py-1 px-4 ${
                                activeTab === 'normal'
                                    ? 'bg-primary-700 text-white'
                                    : 'text-black-100'
                            }  focus:outline-none`}
                            onClick={() => {
                                setTab('normal');
                            }}
                        >
                            Cliente parking
                        </button>
                        <button
                            className={`py-1 px-4 ${
                                activeTab === 'subscriber'
                                    ? 'bg-primary-700 text-white'
                                    : 'text-black-100'
                            }  focus:outline-none`}
                            onClick={() => {
                                setTab('subscriber');
                            }}
                        >
                            Abonado parking
                        </button>
                    </div>
                </div>
            </div>

            <ServiceTable data={tableData}></ServiceTable>
        </div>
    );
}

Service.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
};

export default Service;
