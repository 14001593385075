import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { FiMail } from 'react-icons/fi';

const SUBMIT_URL = 'https://formspree.io/xrgyqbwy';

function ContactForm() {
    const { register, handleSubmit, errors } = useForm(); // initialise the hook
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const onSubmit = async (data) => {
        setLoading(true);

        try {
            const response = await axios.post(SUBMIT_URL, data);
            console.log({ response });
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
        setSubmitted(true);
    };

    if (submitted) {
        return (
            <div className="my-3 text-2xl flex flex-row items-center justify-center space-x-3">
                <div className="text-3xl text-green-500">
                    <FiMail></FiMail>
                </div>
                <p className="text-green-500">
                    ¡Tu mensaje ha sido enviado! ¡Gracias!
                </p>
            </div>
        );
    }

    return (
        <div className="my-3">
            <form
                action={SUBMIT_URL}
                method="POST"
                onSubmit={handleSubmit(onSubmit)}
                className="block space-y-3"
            >
                <div className="space-y-3">
                    <div>
                        <label htmlFor="contactName">Nombre</label>
                        <input
                            id="contactName"
                            type="text"
                            placeholder="Nombre"
                            name="name"
                            className="form-input w-full text-sm"
                            ref={register({ required: true })}
                        />
                        {errors.name && (
                            <p className="text-red-500">
                                El nombre es obligatorio
                            </p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="contactEmail">Email</label>
                        <input
                            id="contactEmail"
                            type="email"
                            placeholder="Email"
                            name="email"
                            className="form-input w-full text-sm"
                            ref={register({
                                required: true,
                                pattern: /^\S+@\S+$/i,
                            })}
                        />
                        {errors.email && (
                            <p className="text-red-500">
                                El email es obligatorio y debe ser una dirección
                                de email válida
                            </p>
                        )}
                    </div>
                </div>

                <div>
                    <label htmlFor="contactMsg">Mensaje</label>
                    <textarea
                        id="contactMsg"
                        placeholder="Mensaje"
                        name="message"
                        className="form-textarea w-full text-sm"
                        ref={register({ required: true })}
                    />
                    {errors.message && (
                        <p className="text-red-500">
                            El mensaje es obligatorio
                        </p>
                    )}
                </div>

                <button
                    type="submit"
                    disabled={submitted || loading}
                    className="flex items-center justify-center px-5 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-primary-100 bg-primary-600 hover:bg-primary-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                >
                    Enviar
                </button>
            </form>
        </div>
    );
}

export default ContactForm;
