import React from 'react';
import Feature from './Feature';
import { FiSun, FiThumbsUp, FiAward } from 'react-icons/fi';

function Features() {
    return (
        <div className="px-3 py-10 md:py-20 bg-white text-black-500 flex-1">
            <h2 className="text-3xl font-bold mb-10 md:mb-20 text-center">
                Qué nos diferencia...
            </h2>

            <div className="space-y-6 md:space-y-0 container m-auto md:flex md:items-stretch md:justify-center md:space-x-5">
                <Feature
                    className="text-primary-700"
                    title="Limpieza especializada"
                    icon={<FiSun></FiSun>}
                >
                    <p>
                        Realizamos limpiezas de tapicería en seco, sólo con
                        líquidos fríos evitando, así, dañar partes de los
                        componentes de la misma como: telas, piel, etc.
                        Productos 100% ecológicos.
                    </p>
                </Feature>
                <Feature
                    className="text-green-600"
                    icon={<FiThumbsUp />}
                    title="Puesta a punto"
                >
                    <p>
                        Pulido, renovación y preparación de los automóviles para
                        su posterior venta
                    </p>
                </Feature>
                <Feature
                    className="text-yellow-800"
                    title="Servicios a medida"
                    icon={<FiAward />}
                >
                    <p>
                        Si el precio de nuestros servicios no se adapta a su
                        presupuesto, nosotros nos adaptamos a él.
                    </p>
                </Feature>
            </div>
        </div>
    );
}

export default Features;
