import React from 'react';
import { FiDownload } from 'react-icons/fi';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

function Covid() {
    const bgImage = useStaticQuery(graphql`
        {
            file(relativePath: { eq: "interior.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 2600, cropFocus: CENTER, quality: 83) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    return (
        <div className="relative">
            <div className="absolute bottom-0 top-0 w-full z-0">
                <Img
                    className="w-full object-cover h-full"
                    title="Bg image"
                    alt="Interior"
                    fluid={bgImage.file.childImageSharp.fluid}
                />
            </div>
            <div className="container px-5 py-24 mx-auto relative z-10">
                <div className="flex flex-col text-center w-full mb-5">
                    <h3 className="text-sm text-red-500 tracking-widest font-medium title-font mb-1">
                        Eficacia 100% garantizada ante virus y bacterias.
                    </h3>
                    <h2 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
                        Desinfección de vehículos
                    </h2>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                        Presentamos las nuevas medidas para la desinfección de
                        sus vehículos.
                    </p>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                        Todos los trabajadores de GCW siguen las medidas de
                        protección personales actualmente necesarias para el
                        desempeño de todos los trabajos.
                    </p>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                        Adaptamos a nuestro servicio habitual de desinfección de
                        vehículos las nuevas necesidades de higiene derivadas de
                        la pandemia por COVID-19.
                    </p>
                </div>

                <a
                    href={'/dossier.pdf'}
                    target="_blank"
                    className="block max-w-xs flex items-center justify-center mx-auto mt-8 text-center text-white bg-primary-700 border-0 py-2 px-8 focus:outline-none hover:bg-primary-600 rounded text-lg"
                >
                    <FiDownload className="text-2xl inline-block mr-2"></FiDownload>
                    Descargar el dossier
                </a>
            </div>
        </div>
    );
}

export default Covid;
