import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundSlider from 'gatsby-image-background-slider';

function HeroCarousel() {
    const images = useStaticQuery(graphql`
        {
            backgrounds: allFile(
                filter: {
                    extension: { regex: "/(jpg)|(jpeg)|(png)/" }
                    dir: { regex: "images/slider/" }
                }
            ) {
                nodes {
                    base
                    childImageSharp {
                        fluid(maxWidth: 3000, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
        }
    `);

    return (
        <div className="w-full h-screen">
            <BackgroundSlider
                query={images}
                className="w-full h-screen"
            ></BackgroundSlider>
        </div>
    );
}

export default HeroCarousel;
