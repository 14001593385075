import React from 'react';
import { FiPhone, FiMapPin } from 'react-icons/fi';
import ContactForm from './ContactForm';

function ContactCard() {
    return (
        <div className="bg-white p-3 border border-gray-300 shadow-md text-black-400 text-sm rounded flex-1 m-3 max-w-xl">
            <h2 className="font-bold text-black text-2xl text-center mb-2">
                Contacto
            </h2>

            <div className="space-y-2 md:flex md:flex-row md:items-center md:justify-center">
                <div className="flex flex-row items-start space-x-3 md:flex-1">
                    <div className="text-3xl text-primary-500">
                        <FiPhone></FiPhone>
                    </div>
                    <div className="flex-1">
                        <h3 className="font-bold text-black text-xl mb-2">
                            Llámenos
                        </h3>
                        <p>
                            Godfather's Car Wash
                            <br />
                            <a
                                className="font-bold text-md"
                                href="phone:+34630608688"
                            >
                                +34 630 608 688 | 642 989 078
                            </a>
                            <br />L - V: 09:30-20:30
                        </p>
                    </div>
                </div>

                <div className="flex flex-row items-start space-x-3 md:flex-1">
                    <div className="text-3xl text-primary-500">
                        <FiMapPin></FiMapPin>
                    </div>
                    <div className="flex-1">
                        <h3 className="font-bold text-black text-xl mb-2">
                            Dónde estamos
                        </h3>
                        <p>
                            C/ San Aquilino, nº 2.
                            <br />
                            Parking público Intercambiador Plaza Castilla,
                            Sótano 2.
                            <br />
                            28029 Madrid
                        </p>
                    </div>
                </div>
            </div>
            <ContactForm></ContactForm>
        </div>
    );
}

export default ContactCard;
