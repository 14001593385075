import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

function ServiceTable({ data }) {
    const categories = data[0].prices.map(({ category }) => category);

    return (
        <div className="container px-5 py-10 mx-auto flex flex-wrap">
            <div className="lg:w-1/4 mt-24 hidden lg:block">
                <div className="mt-px border-t border-gray-700 border-b border-l rounded-tl-lg rounded-bl-lg overflow-hidden">
                    {categories.map((category, idx) => (
                        <p
                            key={category}
                            className={`${
                                idx % 2 === 0 && 'bg-gray-800'
                            } text-white h-12 text-center px-4 flex items-center justify-start -mt-px`}
                        >
                            {category}
                        </p>
                    ))}
                </div>
            </div>
            <div className="flex lg:w-3/4 w-full flex-wrap lg:border border-gray-700 rounded-lg">
                {data.map((item, idx) => (
                    <div
                        key={idx}
                        className="lg:w-1/4 lg:flex-1 lg:mt-px w-full mb-10 lg:mb-0 border-2 border-gray-700 lg:border-none rounded-lg lg:rounded-none relative"
                    >
                        <div className="px-2 text-center h-24 flex flex-col items-center justify-center">
                            <h4 className="text-center text-2xl text-white font-medium leading-none mb-4 mt-2">
                                {item.title}
                            </h4>
                        </div>

                        {item.prices.map(({ category, amount }, idx) => (
                            <p
                                key={idx}
                                className={`${
                                    idx % 2 === 0 && 'bg-gray-800'
                                } text-gray-500 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-700`}
                            >
                                <span className="md:hidden inline-block mr-2">
                                    {category}:
                                </span>
                                {numeral(amount).format('0,0.00')}€
                            </p>
                        ))}
                        <div className="border-t border-gray-700 p-6 text-center rounded-bl-lg">
                            <AnchorLink
                                to="/#contact"
                                className="flex max-w-xs mx-auto items-center mt-auto text-white bg-primary-600 border-0 py-2 px-4 w-full focus:outline-none hover:bg-primary-500 rounded"
                            >
                                Reservar
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    className="w-4 h-4 ml-auto"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </AnchorLink>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

ServiceTable.propTypes = {
    data: PropTypes.array.isRequired,
};

export default ServiceTable;
