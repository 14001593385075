import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/Hero';
import HeroCarousel from '../components/HeroCarousel';
import Contact from '../components/Contact';
import Section from '../components/Section';
import Features from '../components/Features';
import Covid from '../components/Covid';
import Services from '../components/Services';

function IndexPage() {
    return (
        <Layout>
            <SEO
                keywords={[
                    'godfathers car wash',
                    'gcw',
                    'lavadero de coches',
                    'madrid',
                    'plaza de castilla',
                ]}
                title="Godfather's Car Wash"
            />

            <h1 className="hidden">Godfather's Car Wash</h1>

            <Hero></Hero>

            <div className="flex flex-col bg-black-500">
                <Section id="covid19">
                    <Covid></Covid>
                </Section>
                <Section id="features">
                    <Features></Features>
                </Section>
                <Section id="services">
                    <Services></Services>
                </Section>
                <Section id="contact" className="h-screen w-full">
                    <Contact></Contact>
                </Section>
            </div>
        </Layout>
    );
}

export default IndexPage;
