import React from 'react';

function Section({ className, children, id }) {
    return (
        <section id={id} className={`${className || ''}`.trim()}>
            {children}
        </section>
    );
}

export default Section;
