import { graphql, useStaticQuery, Link } from 'gatsby';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

function Header() {
    const [isExpanded, toggleExpansion] = useState(false);
    const { site } = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    /*
        Mobile menu, show/hide based on menu open state.

        Entering: "duration-150 ease-out"
          From: "opacity-0 scale-95"
          To: "opacity-100 scale-100"
        Leaving: "duration-100 ease-in"
          From: "opacity-100 scale-100"
          To: "opacity-0 scale-95"
      */

    const animations = {
        open: { opacity: 1, scale: 1, display: 'block' },
        closed: { opacity: 0, scale: 0, display: 'none' },
    };

    return (
        <>
            <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
                <nav className="relative flex md:flex-col  items-center justify-between sm:h-10 ">
                    <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                        <div className="flex items-center justify-end w-full md:w-auto">
                            <div className="-mr-2 flex items-center md:hidden">
                                <button
                                    type="button"
                                    title="open menu"
                                    onClick={() => toggleExpansion(true)}
                                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-200 hover:text-gray-300 hover:bg-primary-800 focus:outline-none focus:bg-primary-600 focus:text-gray-300 transition duration-150 ease-in-out"
                                >
                                    <svg
                                        className="h-6 w-6"
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="hidden md:block md:ml-3 md:pr-4 space-x-3">
                        <AnchorLink
                            to="/#features"
                            className="inline-block font-medium text-gray-300 hover:text-primary-500 focus:outline-none focus:text-primary-500 transition duration-150 ease-in-out"
                        >
                            Qué nos diferencia
                        </AnchorLink>
                        <AnchorLink
                            to="/#covid19"
                            className="inline-block font-medium text-gray-300 hover:text-primary-500 focus:outline-none focus:text-primary-500 transition duration-150 ease-in-out"
                        >
                            Desinfección
                        </AnchorLink>
                        <AnchorLink
                            to="/#services"
                            className="inline-block font-medium text-gray-300 hover:text-primary-500 focus:outline-none focus:text-primary-500 transition duration-150 ease-in-out"
                        >
                            Servicios
                        </AnchorLink>
                        <AnchorLink
                            to="/#contact"
                            className="inline-block font-medium text-gray-300 hover:text-primary-500 focus:outline-none focus:text-primary-500 transition duration-150 ease-in-out"
                        >
                            Contacto
                        </AnchorLink>
                    </div>
                </nav>

                <motion.div
                    animate={isExpanded ? 'open' : 'closed'}
                    variants={animations}
                    initial="closed"
                    className={`absolute z-50 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden`.trim()}
                >
                    <div className="rounded-lg shadow-md">
                        <div className="rounded-lg bg-black-900 shadow-xs overflow-hidden">
                            <div className="px-2 pt-4 flex flex-row-reverse justify-between">
                                <div className="-mr-2">
                                    <button
                                        type="button"
                                        onClick={() => toggleExpansion(false)}
                                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-200 hover:text-gray-300 hover:bg-primary-800 focus:outline-none focus:bg-primary-800 focus:text-gray-300 transition duration-150 ease-in-out"
                                    >
                                        <svg
                                            className="h-6 w-6"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="px-2 pt-2 pb-3">
                                <AnchorLink
                                    to="/#features"
                                    onClick={() => toggleExpansion(false)}
                                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-gray-200 hover:bg-primary-500 focus:outline-none focus:text-gray-200 focus:bg-primary-500 transition duration-150 ease-in-out"
                                >
                                    Qué nos diferencia
                                </AnchorLink>
                                <AnchorLink
                                    to="/#covid19"
                                    onClick={() => toggleExpansion(false)}
                                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-gray-200 hover:bg-primary-500 focus:outline-none focus:text-gray-200 focus:bg-primary-500 transition duration-150 ease-in-out"
                                >
                                    Desinfección
                                </AnchorLink>
                                <AnchorLink
                                    to="/#services"
                                    onClick={() => toggleExpansion(false)}
                                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-gray-200 hover:bg-primary-500 focus:outline-none focus:text-gray-200 focus:bg-primary-500 transition duration-150 ease-in-out"
                                >
                                    Servicios
                                </AnchorLink>
                                <AnchorLink
                                    to="/#contact"
                                    onClick={() => toggleExpansion(false)}
                                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-gray-200 hover:bg-primary-500 focus:outline-none focus:text-gray-200 focus:bg-primary-500 transition duration-150 ease-in-out"
                                >
                                    Contacto
                                </AnchorLink>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </>
    );
}

export default Header;
