import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ContactCard from './ContactCard';

function Contact() {
    const bgImage = useStaticQuery(graphql`
        {
            file(relativePath: { eq: "plaza_castilla.jpg" }) {
                childImageSharp {
                    fluid(
                        maxHeight: 2600
                        cropFocus: CENTER
                        fit: COVER
                        grayscale: true
                    ) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    return (
        <div className="h-full w-full relative">
            <div className="absolute top-0 bottom-0 w-full z-0">
                <Img
                    className="w-full object-cover h-full"
                    title="Background image"
                    alt="Plaza de Castilla"
                    fluid={bgImage.file.childImageSharp.fluid}
                />
            </div>
            <div className="z-10 relative h-full flex justify-center items-center">
                <ContactCard></ContactCard>
            </div>
        </div>
    );
}

export default Contact;
