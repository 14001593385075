import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Carousel from './HeroCarousel';
import Img from 'gatsby-image';
import Header from './header';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { FiPhone } from 'react-icons/fi';
import styled from 'styled-components';

const HeroContent = styled.div`
    background: rgb(0, 0, 0);
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 35%,
        rgba(0, 0, 0, 0.85) 60%,
        rgba(0, 0, 0, 0.9) 100%
    );
`;

const HeroIntro = styled.h2`
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.28);
`;

function Hero() {
    const homeImage = useStaticQuery(graphql`
        {
            logoImage: file(relativePath: { eq: "logo_v2.png" }) {
                childImageSharp {
                    fluid(maxHeight: 240, quality: 93) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    return (
        <div className="relative overflow-hidden h-screen flex flex-col lg:block">
            <HeroContent className="relative z-30 flex flex-col flex-1 h-screen">
                <Header></Header>
                <div className="flex-1 flex flex-col items-center justify-center">
                    <div className="mx-auto max-w-screen-lg px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 relative">
                        <div className="sm:text-center lg:text-left">
                            <div className="sm:text-center lg:text-left bg-black">
                                <Img
                                    fluid={
                                        homeImage.logoImage.childImageSharp
                                            .fluid
                                    }
                                    alt="Logo"
                                    className="w-1/2 sm:w-1/3 md:w-1/4 max-w-sm m-auto"
                                />
                            </div>
                            <HeroIntro className="text-3xl tracking-tight leading-10 font-extrabold text-gray-200 sm:text-5xl sm:leading-none md:text-6xl">
                                Especialistas en <br className="xl:hidden" />
                                <span className="text-primary-500">
                                    {' '}
                                    limpieza, renovación y desinfección
                                </span>{' '}
                                de vehículos
                            </HeroIntro>

                            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center items-center lg:justify-center sm:space-x-5">
                                <div className="rounded-md shadow">
                                    <AnchorLink
                                        to="/#contact"
                                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                                    >
                                        Reservar
                                    </AnchorLink>
                                </div>

                                <div className="flex flex-row items-center space-x-2 text-black-200 mt-3 sm:mt-0">
                                    <FiPhone></FiPhone>
                                    <p>
                                        <a href="phone:+34630608688">
                                            Reserva telefónica: +34 630 608 688
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HeroContent>
            <div className="absolute z-0 w-full top-0 bottom-0">
                <Carousel></Carousel>
            </div>
        </div>
    );
}

export default Hero;
